// Libs
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

// Components
import Loader from 'components/common/Loader';
import ButtonOptions from 'components/common/ButtonOptions';

//-------------------------------------------------------------------------------------------------------------------

class ApptSignature extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    clearSignature() {
        this.canvasRef.clear();
        this.props.updateFields({ signature: null }, true);
    }

    render() {
        return (
            <div className="appt-signature">
                
                {this.renderInner()}
                
            </div>
        );
    }

    renderInner() {
        const { appt } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <h2>Customer Signature</h2>

            <div className="form-group">
                <label className="form-label">Email address</label>
                <input
                    type="email"
                    className="form-control"
                    defaultValue={appt.customer.emailAddress || ''}
                    onBlur={e => {
                        this.props.updateFields({ 'customer.emailAddress': e.target.value }, true);
                    }}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Mobile number</label>
                <input
                    type="tel"
                    className="form-control"
                    defaultValue={appt.customer.mobileTel || ''}
                    onBlur={e => {
                        this.props.updateFields({ 'customer.mobileTel': e.target.value }, true);
                    }}
                />
            </div>

            <div className="signature-area">

                <div className="signature-area-left">

                    {this.renderLeftCol()}

                </div>

                <div className="signature-area-right">

                    {appt.isCustomerPresent && this.renderRightCol()}

                </div>

            </div>

        </>);
    }

    renderLeftCol() {
        const { appt } = this.props;
        return (<>

            <div className="form-group">
                <label className="form-label">Customer Present</label>
                <ButtonOptions
                    className="vertical-stack"
                    options={[
                        { value: true, text: 'Yes' },
                        { value: false, text: 'No' }
                    ]}
                    value={appt.isCustomerPresent}
                    onSelect={isCustomerPresent => this.props.updateFields({ isCustomerPresent }, true)}
                />
            </div>

            {appt.isCustomerPresent &&
                <div className="bottom">

                    <button className="btn btn-secondary" onClick={() => this.clearSignature()}>
                        <span className="fa fa-times" />{' '}
                        Clear and start again
                    </button>

                </div>
            }

        </>);
    }

    renderRightCol() {
        const { appt } = this.props;

        return (<>

            <div className="declaration">
                We have completed the noted work today at your property.
                Your signature is to confirm that this work has been done in accordance with your requirements.
                This does not affect your statutory rights should you find any problems after we have left.
            </div>

            <SignatureCanvas
                ref={ref => {
                    if (!this.canvasRef) {
                        this.canvasRef = ref;
                        if (appt.signature) {
                            ref.fromDataURL(appt.signature, { width: 500, height: 200 });
                        }
                    }
                }}
                penColor="blue"
                backgroundColor="#ffffff"
                clearOnResize={false}
                canvasProps={{
                    width: 500,
                    height: 200,
                    className: 'signature-canvas'
                }}
                onEnd={(e) => {
                    const signatureFormat = 'image/png';
                    const signature = this.canvasRef.toDataURL(signatureFormat);
                    this.props.updateFields({
                        signature,
                        signatureFormat
                    }, true);
                }}
            />
            <div className="please-sign">
                Please sign in the box above.
            </div>

        </>);
    }
}

export default ApptSignature;