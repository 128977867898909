import Dexie from 'dexie';

const db = new Dexie('ProSweep3');
db.version(1).stores({
    apiCalls: 'id, sequence',
    accounts: 'id',
    appts: 'id, status',
    customers: 'id',
    properties: 'id, customerID',
    chimneyTypes: 'id, accountID',
    products: 'id, isSold, accountID, [accountID+isSold]',
    serviceTypes: 'id',
    daySheetNotes: 'date',
    paymentMethods: 'id',
    priceSchemes: 'id, accountID',
    customerSources: 'id, accountID'
});

export default db;