import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ExpandingTextArea from 'react-expanding-textarea';
import API from 'API';

// Helpers
import ReferenceHelpers from 'helpers/ReferenceHelpers';

import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditChimneyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                isChanged: false,
                accountID: opt.accountID,
                propertyID: opt.propertyID,
                chimney: { ...opt.chimney },
                newChimneyTypeName: null
            }, () => {
                this.load();
            });
        });
    }

    async saveAndClose() {
        const { chimney, newChimneyTypeName } = this.state;
        if (chimney.chimneyTypeID == 'new') {
            if (newChimneyTypeName) {
                const chimneyTypeID = await API.addChimneyType(newChimneyTypeName, this.state.accountID);
                await this.updateFields({ chimneyTypeID });
            } else {
                alert('Please enter a chimney type name')
            }
        }
        this.resolve(this.state.isChanged);
        this.setState({ isOpen: false });
    }

    async load() {
        const chimneyTypes = await API.listChimneyTypes(this.state.accountID);
        this.setState({
            chimneyTypes,
            isLoading: false
        });
    }

    async updateFields(fields) {
        return new Promise(async (resolve, reject) => {
            const { propertyID } = this.state;
            const chimney = { ...this.state.chimney };
            for (let fieldName in fields) {
                const value = fields[fieldName];
                chimney[fieldName] = value;
                await API.saveChimney(propertyID, chimney.id, { [fieldName]: value });
            }
            this.setState({
                chimney,
                isChanged: true
            }, resolve);
        });
    }

    render() {
        const {
            isOpen,
            isLoading
        } = this.state;

        if (!isOpen) {
            return null;
        }

        if (isLoading) {
            return (
                <Modal show onHide={() => this.close()} className="edit-chimney-modal" backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <Loader />
                    </Modal.Body>
                </Modal>
            );
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-chimney-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary ms-auto">
                            Done
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { chimney, chimneyTypes, newChimneyTypeName } = this.state;

        // Set up select options
        let chimneyTypeOptions = [
            { label: '(Unspecified)', value: '' }
        ];
        chimneyTypeOptions = chimneyTypeOptions.concat(chimneyTypes.map(ct => ({
            value: ct.id,
            label: (ct.code ? ct.code + ' - ' : '') + ct.name
        })));
        //let floorOptions = [
        //    { label: '(Unspecified)', value: '' }
        //];
        //floorOptions = floorOptions.concat(ReferenceHelpers.Floors);
        //if (!floorOptions.find(f => chimney.floor == f.value)) {
        //    floorOptions.push({ value: chimney.floor, label: chimney.floor });
        //}

        //let locationOptions = [
        //    { label: '(Unspecified)', value: '' }
        //];
        //locationOptions = locationOptions.concat(ReferenceHelpers.Locations);
        //if (!locationOptions.find(f => chimney.location == f.value)) {
        //    locationOptions.push({ value: chimney.location, label: chimney.location });
        //}
        
        return (<>

            <div className="form-group">
                <label className="form-label">Type</label>
                <select
                    className="form-control"
                    value={chimney.chimneyTypeID || ''}
                    onChange={e => this.updateFields({ chimneyTypeID: e.target.value })}
                >
                    {chimneyTypeOptions.map(ct =>
                        <option key={ct.value} value={ct.value}>
                            {ct.label}
                        </option>
                    )}
                    <option value="new">(Add new...)</option>
                </select>
                {chimney.chimneyTypeID == 'new' &&
                    <input
                        type="text"
                        autoFocus
                        className="form-control"
                        placeholder="Enter name..."
                        value={newChimneyTypeName || ''}
                        onChange={e => this.setState({ newChimneyTypeName: e.target.value })}
                    />
                }
            </div>


            <div className="form-group">
                <label className="form-label">Floor</label>
                {/*
                <select
                    className="form-control"
                    value={chimney.floor || ''}
                    onChange={e => this.updateFields({ floor: e.target.value })}
                >
                    {floorOptions.map(f =>
                        <option key={f.value} value={f.label}>
                            {f.label}
                        </option>
                    )}
                </select>
                */}
                <input
                    type="text"
                    className="form-control"
                    value={chimney.floor || ''}
                    onChange={e => this.updateFields({ floor: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label className="form-label">Location</label>
                {/*
                <select
                    className="form-control"
                    value={chimney.location || ''}
                    onChange={e => this.updateFields({ location: e.target.value })}
                >
                    {locationOptions.map(l =>
                        <option key={l.value} value={l.label}>
                            {l.label}
                        </option>
                    )}
                </select>*/}
                <input
                    type="text"
                    className="form-control"
                    value={chimney.location || ''}
                    onChange={e => this.updateFields({ location: e.target.value })}
                />
            </div>

            {/*
            <div className="form-group">
                <label className="form-label">Notes</label>
                <ExpandingTextArea
                    className="form-control"
                    key={chimney.notes || ''}
                    defaultValue={chimney.notes || ''}
                    onBlur={e => this.updateFields({ notes: e.target.value })}
                    rows={2}
                />
            </div>
            */}

        </>);
    }
}

export default EditChimneyModal;