export default class TextHelpers {

    static DaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    static formatName(part1, part2, part3) {
        var nameParts = [part1, part2, part3].filter(np => !!((np || '').trim())).map(np => (np || ''));
        return nameParts.join(' ') || '';
    }
   
    static simplifySearchString(str) {
        return (str || '').toLowerCase().replace(/[^a-z0-9 -'&@]/, '').trim();
    }

    static formatPercentage(percentage, numDP) {
        if (typeof (numDP) == 'undefined') {
            numDP = 2;
        }
        const div = Math.pow(10, numDP);
        let value = Math.round(percentage * 100 * div) / div;
        return value;
    }

    static formatCurrency(amount, opt) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            ...opt
        });
        let str = formatter.format(amount);
        str = str.replace('.00', '');
        return str;
    }

    static getRandomGUID(size) {
        if (typeof (size) == 'undefined') size = 16;
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
        const data = new Uint8Array(4 * size);
        window.crypto.getRandomValues(data);
        const result = [];
        for (let i = 0; i < size; i++) {
            const rnd = new DataView(data.buffer).getUint32(i * 4, true);
            const idx = rnd % chars.length;
            result.push(chars[idx]);
        }
        return result.join('');
    }

    static getFriendlyName(type, value, emptyText) {
        value = (value || '').toLowerCase();
        if (!value) {
            return emptyText || '';
        }

        switch (type) {
            case 'SearchResult':
                switch (value) {
                    case 'customer': return 'Customer';
                    case 'appt': return 'Appt';
                    default: return value;
                }
            case 'PaymentMethod':
                switch (value) {
                    case 'cash': return 'Cash';
                    case 'card': return 'Card';
                    case 'cheque': return 'Cheque';
                    case 'bacs': return 'BACS';
                    case 'stripe': return 'Stripe';
                }
                return value;
        }
        return value;
    }

    static getSearchResultIcon(type) {
        switch (type) {
            case 'customer': return 'fa-solid fa-house-chimney';
        }
        return null;
    }

    static formatDuration(mins, interval) {
        mins = Math.ceil(mins || 0);
        if (typeof (interval) == 'undefined') {
            interval = 15;
        }
        const parts = [];
        mins = Math.floor(mins * interval) / interval;
        const hours = Math.floor(mins / 60);
        if (hours > 0) {
            parts.push(hours + 'hr');
            mins -= hours * 60;
        }
        if (mins > 0) {
            parts.push(mins + 'min');
        }
        return parts.join(' ');
    }

    static buildURL(url, queryString) {
        let isFirstQS = true;
        if (queryString) {
            for (var key in queryString) {
                let value = queryString[key];
                if (value) {
                    url += (isFirstQS ? '?' : '&') + key + '=' + encodeURIComponent(value);
                    isFirstQS = false;
                }
            }
        }
        return url;
    }

    static formatAddress(address, separator) {
        if (address == null) return '';
        if (typeof (separator) == 'undefined') {
            separator = ', ';
        }
        const result = [
            address.line1,
            address.line2,
            address.line3,
            address.town,
            address.postcode
        ].map(a => (a || '').trim())
            .filter(a => a)
            .join(separator);
        return result;
    }

    static escapeHTML(html) {
        html = html.replace(/&/g, '&amp;');
        html = html.replace(/>/g, '&gt;');
        html = html.replace(/</g, '&lt;');
        return html;
    }

    static escapeJSString(str) {
        str = str || '';
        str = str.replace(/'/g, '\\\'');
        str = str.replace(/\r/g, '\\r');
        str = str.replace(/\n/g, '\\n');
        return str;
    }

    static escapeAttribute(str) {
        str = str || '';
        str = str.replace(/"/g, '&quot;');
        str = str.replace(/\\/g, '\\\\');
        return str;
    }

    static getApptSummary(appt) {
        const chimneys = {};
        const services = {};
        appt.services.forEach(ast => {
            if (!ast.serviceType) return;
            const serviceTypeCode = (ast.serviceType.code ? ast.serviceType.code.toUpperCase() : ast.serviceType.name);
            switch (serviceTypeCode) {
                case 'SWEEP':
                    const chimneyTypeCode = (ast.chimneyType && ast.chimneyType.code ? ast.chimneyType.code : 'OTHER');
                    chimneys[chimneyTypeCode] = (chimneys[chimneyTypeCode] || 0) + 1;
                    break;
                default:
                    services[serviceTypeCode] = (services[serviceTypeCode] || 0) + 1;
                    break;
            }
        });
        const results = [];
        Object.keys(chimneys).forEach(c => {
            results.push(`${chimneys[c]} × ${c}`);
        });
        Object.keys(services).forEach(s => {
            results.push(`${services[s]} × ${s}`);
        });
        return results.join(', ');
    }

    static normalisePostcode(postcode) {
        // Basic normalise
        postcode = postcode || '';
        postcode = postcode.toUpperCase();
        postcode = postcode.replace(/[^A-Z0-9]/, '');

        // Add a space at the correct place
        let insertPos = -1;
        switch (postcode.length) {
            case 5: insertPos = 2; break;
            case 6: insertPos = 3; break;
            case 7: insertPos = 4; break;
        }
        if (insertPos != -1) {
            postcode = postcode.slice(0, insertPos) + ' ' + postcode.slice(insertPos);
        }
        return postcode;
    }

    static isValidUKPostcode(postcode) {
        // https://www.qodo.co.uk/blog/javascript-check-if-a-uk-postcode-is-valid/
        // Yes I know this doesn't cover 100% of valid UK postcodes
        const postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
        return postcodeRegEx.test(postcode); 
    }

    static getApptServiceStatusFriendly(status) {
        switch (status) {
            case 'OK': return 'Completed ok';
            case 'Fail': return 'Could not complete';
            case 'Condemn': return 'Not safe for use (condemn)';
        }
        return status;
    }

    static getApptServiceReasonForFailureFriendly(status) {
        switch (status) {
            case 'NoAccess': return 'No access';
            case 'NestInFlue': return 'Nest in flue';
            case 'MasonryInFlue': return 'Masonry in flue';
            case 'ExternalAccessOnly': return 'External access only';
            case 'RoofAccessRequired': return 'Roof access required';
            case 'Other': return 'Other (please specify)';
        }
        return status;
    }

    static getCOAlarmStatusFriendly(status) {
        switch (status) {
            case 'Working': return 'Fitted & working';
            case 'NotWorking': return 'Fitted, not working';
            case 'NotFitted': return 'Not fitted';
        }
        return status;
    }

    static getNextSweepRecommendedInFriendly(nextSweepRecommendedIn) {
        switch (nextSweepRecommendedIn || null) {
            case null: return 'N/A';
            case 1: return '1 Month';
            default: return `${nextSweepRecommendedIn} Months`;
        }
    }
}

window.TextHelpers = TextHelpers;
