// Libs
import React from 'react';
import moment from 'moment';
import db from 'db';

// Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';
import CurrencyInput from 'components/common/CurrencyInput';
import ButtonOptions from 'components/common/ButtonOptions';
import DatePicker from 'components/common/DatePicker';
import PreBookAppt from 'components/appt/PreBookAppt';

//-------------------------------------------------------------------------------------------------------------------

class ApptSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showPreBook: !!props.appt.preBookAppt
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { appt } = this.props;
        const hasSweepOrNest = appt.services.some(ast => ast.serviceType.code == 'sweep' || ast.serviceType.code == 'nest')
        if (hasSweepOrNest && !appt.certAction) {
            if(appt.customer.emailAddress) {
                await this.props.updateFields({ certAction: 'Email' }, true);
            } else {
                await this.props.updateFields({ certAction: 'SendManually' }, true);
            }
            
        }
        await this.populateServicePrices();
        this.setState({ isLoading: false });
    }

    async populateServicePrices() {
        const { appt } = this.props;
        console.log("Populate Service Prices");
        // Get property and price scheme and ensure everything exists
        const property = await db.properties.get(appt.propertyID);
        if (!property || !property.priceSchemeID) return;
        const priceScheme = await db.priceSchemes.get(property.priceSchemeID);
        if (!priceScheme || !priceScheme.prices) return;

        // Enumerate each service...
        let servicePrice = 0;
        const quantitiesByServiceTypeID = {};
        for (let i = 0; i < appt.services.length; i++) {
            const service = appt.services[i];

            if(service.isDeleted) continue;

            // Get pricing for this service type
            const pricing = priceScheme.prices.find(psst => psst.serviceTypeID == service.serviceTypeID);

            // Skip if needed
            if (!pricing || service.price || !service.chimneyID || service.price === 0) {
                servicePrice += service.price;
                continue;
            }

            // Set price to first or second chimney
            if (!quantitiesByServiceTypeID[service.serviceTypeID]) {
                quantitiesByServiceTypeID[service.serviceTypeID] = 1;

            }
            if (quantitiesByServiceTypeID[service.serviceTypeID] == 1) {
                service.price = pricing.firstPrice;
            } else {
                service.price = pricing.secondPlusPrice;
            }
            // await this.props.updateFields({ service }, true);
            await this.props.updateServiceFields(service.chimneyID, service.serviceTypeID, { price: service.price }, true);
            quantitiesByServiceTypeID[service.serviceTypeID]++;
            servicePrice += service.price;
        }

        let services = appt.services;
        await this.props.updateFields({ services }, false);

        console.log(servicePrice);
        // Update appt service price if it changed
        if (servicePrice != appt.servicePrice) {
            await this.props.updateFields({ servicePrice }, true);
        }
    }

    async addPayment() {
        const { appt } = this.props;
        const payments = [...appt.payments];
        const { balance, amountToPay } = API.getApptPaymentStats(appt);
        const amount = (balance - amountToPay);
        if (amount > 0) {
            const id = TextHelpers.getRandomGUID();
            const newFields = {
                date: moment().format('YYYY-MM-DD'),
                amount
            };
            await this.props.updatePaymentFields(id, newFields, true);
            payments.push({ id, ...newFields });
            this.props.updateFields({ payments }, false);
        }
    }

    async removeUnpaidPayments() {
        const { appt } = this.props;
        const newPayments = [];
        for (let i = 0; i < appt.payments.length; i++) {
            const payment = appt.payments[i];
            if (payment.isPaid) {
                newPayments.push(payment);
            } else {
                await this.props.updatePaymentFields(payment.id, { isDeleted: true }, true);
            }
        }
        this.props.updateFields({ payments: newPayments });
    }

    render() {
        return (
            <div className="appt-summary">
                
                {this.renderInner()}
                
            </div>
        );
    }

    renderInner() {
        const { isLoading } = this.state;
        const { appt } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <h2>Summary of Appointment</h2>

            <table className="table table-bordered appt-summary-table">
                <thead>
                    <tr>
                        <th className="item-col">Item</th>
                        <th className="details-col">Details</th>
                        <th className="price-col">Charge</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {this.renderServices()}
                    {this.renderProducts()}

                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={2}>
                            TOTAL
                        </td>
                        <td className="price-col">
                            {TextHelpers.formatCurrency(Number(appt.servicePrice || 0) + Number(appt.productPrice || 0))}
                        </td>
                    </tr>
                </tfoot>
            </table>

            <div className="row">

                <div className="col-md-8">

                    {this.renderPayments()}

                </div>

                <div className="col-md-4">

                    {this.renderCertificateOptions()}

                </div>

            </div>

            {this.renderRebookMonths()}
            {this.renderPrebook()}

        </>);
    }

    renderServices() {
        const { appt } = this.props;
        return appt.services.filter(ast => !ast.isDeleted).map((ast, index) => {
            const isSweepOrNest = (ast.serviceType.code == 'sweep' || ast.serviceType.code == 'nest');
            const isCowl = (ast.serviceType.code == 'cowl');

            // console.log("Render Services:");
            // console.log(ast);

            return (
                <tr key={index}>
                    <td className="item-col">

                        <div className="job-name">
                            {ast.serviceType.name}
                        </div>
                        
                        {ast.chimney &&
                            <div>
                                {ast.chimneyType && <>{ast.chimneyType.name},{' '}</>}
                                {ast.chimney.floor && <>{ast.chimney.floor} floor{' '}</>}
                                {ast.chimney.location}
                            </div>
                        }

                    </td>
                    <td className="details-col">
                        
                        {ast.serviceType.requiresChimney &&
                            this.renderResultField('Result', TextHelpers.getApptServiceStatusFriendly(ast.status) || 'NOT COMPLETED')
                        }

                        {isSweepOrNest && ast.status && <>
                            {this.renderResultField('Recommendations', [
                                ast.isFlueFreeRecommended ? 'We recommend regular use of HotSpot Flue Free to help prevent the tar/creosote buildup' : null
                            ])}
                            {this.renderResultField('Other recommendations', ast.otherRecommendations)}
                            {this.renderResultField('Carbon monoxide alarm', TextHelpers.getCOAlarmStatusFriendly(ast.coAlarmStatus))}
                            {this.renderResultField('Carbon monoxide alarm in correct location',
                                ast.isCOAlarmLocationCorrect === true ? 'Yes' :
                                ast.isCOAlarmLocationCorrect === false ? 'No' :
                                null
                            )}
                            {this.renderResultField('Appropriate flue terminal fitted',
                                ast.isFlueTerminalFitted === true ? 'Yes' :
                                ast.isFlueTerminalFitted === false ? 'No' :
                                null
                            )}
                            {this.renderResultField('Draw (smoke) test',
                                ast.isDrawTestPassed === true ? 'Pass' :
                                    ast.isDrawTestPassed === false ? 'Fail' :
                                        'Not performed'
                            )}
                            {this.renderResultField('Next sweep recommended in', TextHelpers.getNextSweepRecommendedInFriendly(ast.nextSweepInMonths))}
                        </>}

                        {this.renderResultField('Reason for failure', TextHelpers.getApptServiceReasonForFailureFriendly(ast.failReason))}
                        {this.renderResultField('Failure notes', ast.failureNotes)}
                        {/*{this.renderResultField('Notes', ast.notes)}*/}

                        {isCowl && <>
                            {ast.cowlProduct && this.renderResultField('Cowl type', ast.cowlProduct.name)}
                            {this.renderResultField('Quantity', ast.cowlQuantity)}
                        </>}

                    </td>
                    <td className="price-col">

                        <CurrencyInput
                            className="form-control"
                            value={ast.price || 0}
                            onValueChange={(values) => {
                                const value = Number(values.value) || 0;
                                this.props.updateServiceFields(ast.chimneyID, ast.serviceTypeID, { price: value }, false);
                            }}
                            onBlur={(price) => {
                                this.props.updateServiceFields(ast.chimneyID, ast.serviceTypeID, { price }, true)
                            }}
                        />

                    </td>
                </tr>
            );
        });
    }

    renderProducts() {
        const { appt } = this.props;

        if (appt.products.length == 0) {
            return null;
        }

        return (
            <tr>
                <td className="item-col">
                    
                    Products sold / other services
                    
                </td>
                <td className="details-col">

                    {appt.products.map((ap, index) =>
                        <div key={index} className="product">
                            <span className="product-name">
                                {ap.product ? ap.product.name : ap.name}{' '}
                                ({TextHelpers.formatCurrency(ap.unitPrice)})
                            </span>
                            <span className="product-quantity">
                                {' '}&times; {ap.quantity}{' '}
                                = {TextHelpers.formatCurrency(ap.totalPrice)}
                            </span>

                        </div>
                    )}
                        
                </td>
                <td className="price-col">

                    {TextHelpers.formatCurrency(appt.productPrice)}
                        
                </td>
            </tr>
        );

    }

    renderResultField(name, value) {
        if (!value) {
            return null;
        }
        if (Array.isArray(value)) {
            value = value.filter(v => !!v);
            if (value.length == 0) {
                return null;
            }
            value = value.map((v, index) =>
                <div key={index}>
                    {v}
                </div>
            );
        }
        return (
            <div className="result-field">
                <span className="field-name">
                    {name}:
                </span>
                <span className="field-value">
                    {value}
                </span>
            </div>
        );
    }

    renderPayments() {
        const { appt } = this.props;
        const { amountPaid, amountToPay, balance } = API.getApptPaymentStats(appt);
        const balanceAfterPayments = balance - amountToPay;
        const paymentMethods = API.listPaymentMethods(appt.accountID);
        
        return (<>

            <h2>Payment</h2>

            <div className="row mb-3">

                <div className="col-lg-6">

                    <div className="financial-summary">
                        <div className="field">
                            <div className="field-name">Already paid</div>
                            <div className="field-value">{TextHelpers.formatCurrency(amountPaid)}</div>
                        </div>
                        {amountToPay > 0 &&
                            <div className="field">
                                <div className="field-name">To pay today</div>
                                <div className="field-value">{TextHelpers.formatCurrency(amountToPay)}</div>
                            </div>
                        }
                        <div className={`field ${balanceAfterPayments > 0 ? 'outstanding-balance' : 'balance-paid'}`}>
                            <div className="field-name">Remaining balance</div>
                            <div className="field-value">{TextHelpers.formatCurrency(balanceAfterPayments)}</div>
                        </div>
                    </div>

                </div>

                <div className="col-lg-6">

                    <ButtonOptions
                        className="vertical-stack"
                        options={[
                            { value: 'PayNow', text: 'Pay balance now' },
                            { value: 'PaymentToFollow', text: 'Payment to follow' },
                            { value: 'SendInvoice', text: 'Send invoice' }
                        ]}
                        value={appt.paymentType}
                        onSelect={async paymentType => {
                            await this.props.updateFields({ paymentType }, true);
                            if (paymentType == 'PayNow' && balanceAfterPayments > 0) {
                                this.addPayment();
                            }
                            else if (paymentType == 'PaymentToFollow' || paymentType == 'SendInvoice') {
                                this.removeUnpaidPayments();
                            }
                        }}
                    />
                    {(appt.paymentType == 'SendInvoice' || appt.paymentType == 'PaymentToFollow') && !appt.customer.emailAddress &&
                        <div className="warning-text mt-1">
                            <span className="fa-solid fa-exclamation-triangle" />{' '}
                            Customer has no email address specified
                        </div>
                    }
                </div>

            </div>

            {(appt.payments.length > 0 || appt.paymentType == 'PayNow') &&
                <table className="table table-bordered appt-payments-table mb-3">
                    <thead>
                        <tr>
                            <th className="date-col">Date</th>
                            <th className="amount-col">Amount</th>
                            <th className="method-col">Method</th>
                            <th className="actions-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {appt.payments.map(ap =>
                            <tr key={ap.id}>
                                <td className="date-col">

                                    {ap.isDeposit ?
                                        <p className="form-control-plaintext">
                                            {moment(ap.date).format('DD/MM/YYYY')}
                                        </p> :
                                        <DatePicker
                                            className="form-control"
                                            value={ap.date ? moment(ap.date).toDate() : null}
                                            onChange={date => this.props.updatePaymentFields(ap.id, { date }, true)}
                                        />
                                    }

                                </td>
                                <td className="amount-col">

                                    {ap.isDeposit ?
                                        <p className="form-control-plaintext">
                                            {TextHelpers.formatCurrency(ap.amount)}
                                        </p> :
                                        <CurrencyInput
                                            className="form-control"
                                            value={ap.amount || ''}
                                            onValueChange={(values) => {
                                                const amount = Number(values.value) || '';
                                                this.props.updatePaymentFields(ap.id, { amount }, false)
                                            }}
                                            onBlur={amount => this.props.updatePaymentFields(ap.id, { amount }, true)}
                                        />
                                    }

                                </td>
                                <td className="method-col">

                                    {ap.isDeposit ?
                                        <p className="form-control-plaintext">
                                            {(paymentMethods.find(pm => pm.id == ap.paymentMethodID) || {}).name}
                                            {' '}(Deposit)
                                        </p> :
                                        <ButtonOptions
                                            options={paymentMethods.map(pm => ({
                                                value: pm.id,
                                                text: pm.name
                                            }))}
                                            value={ap.paymentMethodID}
                                            onSelect={paymentMethodID => this.props.updatePaymentFields(ap.id, { paymentMethodID }, true)}
                                        />
                                    }

                                </td>
                                <td className="action-col">

                                    {!ap.isPaid &&
                                        <button type="button" className="btn btn-danger" onClick={() => this.props.removePayment(ap.id)}>
                                            <span className="fa-solid fa-times" />
                                        </button>
                                    }

                                </td>
                            </tr>
                        )}
                        {appt.payments.length == 0 &&
                            <tr>
                                <td colSpan={4}>
                                    <div className="empty-text">
                                        No payments made yet
                                    </div>
                                </td>
                            </tr>
                        }
                        {balance > 0 && appt.paymentType == 'PayNow' &&
                            <tr>
                                <td colSpan={4}>

                                    <button className="btn btn-secondary" onClick={() => this.addPayment()}>
                                        <span className="fa-regular fa-plus" />{' '}
                                        Add payment
                                    </button>

                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            }

        </>);
    }

    renderCertificateOptions() {
        const { appt } = this.props;
        const hasSweepOrNest = appt.services.some(ast => ast.serviceType.code == 'sweep' || ast.serviceType.code == 'nest')

        return (<>

            <h2>Certificate</h2>

            {hasSweepOrNest ?
                <ButtonOptions
                    className="vertical-stack mb-1"
                    options={[
                        { value: 'Email', text: 'Email now' },
                        //{ value: 'EmailUponPayment', text: 'Email upon payment' },
                        { value: 'SendManually', text: 'Send manually later' },
                        { value: 'NotNeeded', text: 'Not needed' }
                    ]}
                    value={appt.certAction}
                    onSelect={certAction => this.props.updateFields({ certAction }, true)}
                /> :
                <div className="empty-text">
                    Not required
                </div>
            }
            {hasSweepOrNest && (appt.certAction == 'Email' || appt.certAction == 'EmailUponPayment') && !appt.customer.emailAddress &&
                <div className="warning-text mt-1 mb-3">
                    <span className="fa-solid fa-exclamation-triangle" />{' '}
                    Customer has no email address specified
                </div>
            }

        </>);
    }

    renderRebookMonths() {
        const { appt } = this.props;

        return (<>

            <h2>Rebook months</h2>

            <div className="mb-3">
                <ButtonOptions
                    options={ReferenceHelpers.Months}
                    value={appt.property.rebookMonths}
                    onSelect={value => {
                        const rebookMonths = [...appt.property.rebookMonths];
                        const index = rebookMonths.indexOf(value);
                        if (index == -1) {
                            rebookMonths.push(value);
                        } else {
                            rebookMonths.splice(index, 1);
                        }
                        this.props.updateFields({ 'property.rebookMonths': rebookMonths }, true)
                    }}
                />
            </div>

        </>);
    }

    renderPrebook() {
        const { appt } = this.props;
        const { showPreBook } = this.state;

        if (appt.preBookApptID) {
            return (<>

                <h2>Pre-book</h2>
                <p>Next appointment has been pre-booked</p>

            </>);
        }

        return (<>
            
            <h2>Pre-book</h2>

            <ButtonOptions
                options={[
                    { value: true, text: 'Pre-book next appointment (requires internet connection)' },
                    { value: false, text: 'Do not pre-book' }
                ]}
                value={showPreBook}
                onSelect={showPreBook => {
                    this.setState({ showPreBook })
                    if (!showPreBook) {
                        this.props.updateFields({ preBookAppt: null }, true);
                    }
                }}
            />

            {showPreBook &&
                <div className="mt-3">
                    <PreBookAppt
                        appt={appt}
                        selectedAppt={appt.preBookAppt}
                        updateFields={this.props.updateFields}
                    />
                </div>
            }
            
        </>);
    }
}

export default ApptSummary;