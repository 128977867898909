// Libs
import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import TextHelpers from 'helpers/TextHelpers';
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import NavPills from 'components/common/NavPills';
import ApptDetails from 'components/appt/ApptDetails';
import ApptJobs from 'components/appt/ApptJobs';
import ApptProducts from 'components/appt/ApptProducts';
import ApptSummary from 'components/appt/ApptSummary';
import ApptSignature from 'components/appt/ApptSignature';
import ErrorBoundary from 'components/ErrorBoundary';

//-------------------------------------------------------------------------------------------------------------------

class Appt extends React.Component {

    constructor(props) {
        super(props);

        this.load = this.load.bind(this);
        this.setIsLoading = this.setIsLoading.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.updateFields = this.updateFields.bind(this);
        this.updateServiceFields = this.updateServiceFields.bind(this);
        this.addService = this.addService.bind(this);
        this.addNonChimneyService = this.addNonChimneyService.bind(this);
        this.removeService = this.removeService.bind(this);
        this.removeNonChimneyService = this.removeNonChimneyService.bind(this);
        this.updateProductFields = this.updateProductFields.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.toggleSweepNest = this.toggleSweepNest.bind(this);
        this.updatePaymentFields = this.updatePaymentFields.bind(this);
        this.removePayment = this.removePayment.bind(this);
        this.updateChimneyFields = this.updateChimneyFields.bind(this);

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.load();
    }

    componentDidUpdate(oldProps) {
        if ((this.props.tab || '') != (oldProps.tab || '')) {
            window.scrollTo(0, 0);
        }
    }

    setIsLoading(isLoading) {
        this.setState({ isLoading });
    }

    async load() {
        const { id } = this.props;
        const appt = await API.getAppt(id);
        if (!appt) {
            this.props.history.replace('/day-sheet');
            return;
        }
        const customerSources = await API.listCustomerSources(appt.accountID);
        const priceSchemes = await API.listPriceSchemes(appt.accountID);
        const products = await API.listProducts(appt.accountID);
        if (appt.property) {
            this.props.fns.setTopNavContent(TextHelpers.formatAddress(appt.property.address));
        } else {
            this.props.fns.setTopNavContent(null);
        }
        this.setState({
            appt,
            customerSources,
            priceSchemes,
            products,
            isLoading: false
        });
    }

    getFieldValue(fullFieldName) {
        const { appt } = this.state;
        if (!appt) return null;
        const { fieldName, entity } = this.getFieldParts(fullFieldName);
        switch (entity || 'appt') {
            case 'appt':     return appt[fieldName];
            case 'customer': return appt.customer[fieldName];
            case 'property': return appt.property[fieldName];
            case 'address':  return appt.property.address[fieldName];
        }
    }

    updateFields(fields, save) {
        return new Promise((resolve) => {
            const appt = { ...this.state.appt };
            const updatedFieldsByEntity = {};

            for (let fullFieldName in fields) {
                const value = fields[fullFieldName];
                let { fieldName, entity } = this.getFieldParts(fullFieldName);
                // console.log("UpdateFields:")
                // console.log(fullFieldName);
                // console.log(fieldName);
                // console.log(entity);
                // console.log(value);
                // console.log("Save:" + save);

                // Get correct field value
                entity = entity || 'appt';
                switch (entity) {
                    case 'appt':
                        appt[fieldName] = value;
                        break;
                    case 'customer':
                        appt.customer = { ...appt.customer };
                        appt.customer[fieldName] = value;
                        break;
                    case 'property':
                        appt.property = { ...appt.property };
                        appt.property[fieldName] = value;
                        break;
                    case 'address':
                        appt.property = { ...appt.property };
                        appt.property.address = { ...appt.property.address };
                        appt.property.address[fieldName] = value;
                        break;
                }
                if (!updatedFieldsByEntity[entity]) {
                    updatedFieldsByEntity[entity] = {};
                }
                updatedFieldsByEntity[entity][fieldName] = value;

                // If updating products or services, calculate total price
                if (fieldName == 'products') {
                    appt.productPrice = 0;
                    appt.products.forEach(ap => {
                        appt.productPrice += Number(ap.totalPrice || 0);
                    });
                    updatedFieldsByEntity['appt'].productPrice = appt.productPrice;
                }
                else if (fieldName == 'services') {
                    appt.servicePrice = 0;
                    appt.services.filter(x => !x.isDeleted).forEach(ap => {
                        appt.servicePrice += Number(ap.price || 0);
                    });
                    updatedFieldsByEntity['appt'].servicePrice = appt.servicePrice;
                    // updatedFieldsByEntity['appt'].services = appt.services;
                }
                // else if (fieldName == 'paymentType' && value == 'SendInvoice') {
                //     const hasSweepOrNest = appt.services.some(ast => ast.serviceType.code == 'sweep' || ast.serviceType.code == 'nest')
                //     if (hasSweepOrNest) {
                //         const account = API.accountsByID[appt.accountID];
                //         if (!account.emailCertForInvoiceAppt) {
                //             appt.certAction = 'SendManually';
                //             updatedFieldsByEntity['appt'].certAction = 'SendManually';
                //         }
                //     }
                // }
            }

            this.setState({
                appt
            }, () => {
                // Save to local DB and server
                if (save) {
                    // console.log(updatedFieldsByEntity)
                    if (updatedFieldsByEntity['appt']) {
                        API.saveAppt(appt.id, updatedFieldsByEntity['appt']);
                    }
                    if (updatedFieldsByEntity['customer']) {
                        API.saveCustomer(appt.customerID, updatedFieldsByEntity['customer']);
                    }
                    if (updatedFieldsByEntity['property'] || updatedFieldsByEntity['address']) {
                        const allFields =
                        {
                            ...(updatedFieldsByEntity['property'] || {}),
                            ...(updatedFieldsByEntity['address'])
                        };
                        API.saveProperty(appt.propertyID, allFields);
                    }
                }

                resolve();
            });
        });
    }

    //---------------------------------------------------------------------------------------------------------------
    // Appt
    //---------------------------------------------------------------------------------------------------------------

    getFieldParts(fieldName) {
        let entity = '';
        const match = fieldName.match(/(.+)\.(.+)/);
        if (match) {
            entity = match[1] || '';
            fieldName = match[2] || '';
        }
        return { entity, fieldName };
    }

    async tryCompleteAppt() {
        const error = this.validateApptComplete();
        if (error) {
            alert(error);
        } else {
            const { appt } = this.state;

            // Check for any with no price
            const numWithNoPrice = appt.services.filter(ast => !Number(ast.price)).length;
            if (numWithNoPrice > 0) {
                if (!window.confirm(`There ${(numWithNoPrice == 1 ? 'is one service' : 'are ' + numWithNoPrice + ' services')} with no price. Is this correct?`)) {
                    return;
                }
            }

            // Proceed
            const confirm = window.confirm('Complete this appointment?');
            if (confirm) {
                this.setIsLoading(true);
                await this.updateFields({ status: 'Completed' }, true);
                this.props.history.replace(`/day-sheet?show-next-appt=true`);
            }
        }
    }

    validateApptComplete() {
        const { appt } = this.state;
        for (let i = 0; i < appt.services.length; i++) {
            const ast = appt.services[i];
            if (!ast.status && ast.chimney) {
                return `Please provide a result for: ${ast.serviceType.name}, ${ast.chimney.floor ? ast.chimney.floor + ' floor ' : ''}${ast.chimney.location}`;
            }
        }
        if (!appt.paymentType) {
            return 'Please specify payment type';
        }
        const hasSweepOrNest = appt.services.some(ast => ast.serviceType.code == 'sweep' || ast.serviceType.code == 'nest')
        if (hasSweepOrNest) {
            if (!appt.certAction) {
                return 'Please specify certificate action';
            }
            else if (appt.certAction == 'Email') {
                if (!appt.customer.emailAddress) {
                    return 'You have selected to email the certificate, but the customer has no email address specified';
                }
            }
        }
        for (let i = 0; i < appt.payments.length; i++) {
            const ap = appt.payments[i];
            if (!ap.isDeposit && !ap.paymentMethodID) {
                return `Please specify a payment method for all payments`;
            }
        }
        if (appt.paymentType == 'PayNow') {
            const { balance, amountToPay } = API.getApptPaymentStats(appt);
            if (balance - amountToPay > 0) {
                return 'If payment type is \'Pay Now\', you must add payments until the balance is £0';
            }
        }
        if (appt.isCustomerPresent === true) {
            if (!appt.signature) {
                return 'If the customer is present, you must provide a signature';
            }
        } else if (appt.isCustomerPresent !== false) {
            return 'Please specify whether or not the customer is present';
        }
    }

    //---------------------------------------------------------------------------------------------------------------
    // Chimney
    //---------------------------------------------------------------------------------------------------------------

    updateChimneyFields(chimneyID, fields) {
        const { appt } = this.state;
        const services = [...appt.services];
        const chimney = appt.property.chimneys.find(c => c.id == chimneyID);

        for (let fieldName in fields) {
            const value = fields[fieldName];
            chimney[fieldName] = value;
            for (let i = 0; i < services.length; i++) {
                const service = services[i];
                if (service.chimneyID == chimneyID) {
                    service.chimney = { ...service.chimney };
                    service.chimney[fieldName] = value;
                }
            }
        }

        appt.services = services;
        this.updateFields({ services }, false);
        API.saveChimney(appt.propertyID, chimneyID, fields);
    }

    //---------------------------------------------------------------------------------------------------------------
    // Service
    //---------------------------------------------------------------------------------------------------------------

    updateServiceFields(chimneyID, serviceTypeID, fields, save) {
        return new Promise(async (resolve, reject) => {
            const { appt } = this.state;
            const services = [...appt.services];
            const index = services.findIndex(ast => ast.chimneyID == chimneyID && ast.serviceTypeID == serviceTypeID);
            const service = { ...services[index] };
            console.log("UpdateServiceFields:");
            console.log(services);
            for (let fieldName in fields) {
                const value = fields[fieldName];
                service[fieldName] = value;
            }
            services[index] = service;
            await this.updateFields({ services }, false);
            if (save) {
                API.saveApptService(this.state.appt.id, chimneyID, serviceTypeID, fields);
            }
            resolve();
        });
    }

    toggleSweepNest(chimneyID, serviceTypeID, isNest) {
        let code = (isNest ? 'nest' : 'sweep');
        this.updateServiceFields(chimneyID, serviceTypeID, { serviceTypeID: API.serviceTypesByCode[code].id }, true);
    }

    async addService(chimneyID, code) {
        const { appt } = this.state;
        const services = [...appt.services];
        const serviceType = API.serviceTypesByCode[code];
        const index = services.findIndex(ast => ast.chimneyID == chimneyID && ast.serviceTypeID == serviceType.id);
        if (index == -1) {
            const chimney = appt.property.chimneys.find(c => c.id == chimneyID);
            services.push({
                chimneyID,
                chimney,
                serviceTypeID: serviceType.id,
                serviceType: API.getServiceType(serviceType.id)
            });
            this.updateFields({ services }, false);
            API.saveApptService(this.state.appt.id, chimneyID, serviceType.id, { isDeleted: false });
        }
    }

    async addNonChimneyService(serviceTypeID) {
        const { appt } = this.state;
        const services = [...appt.services];
        const index = services.findIndex(ast => ast.serviceTypeID == serviceTypeID);
        if (index == -1) {
            services.push({
                serviceTypeID: serviceTypeID,
                serviceType: API.getServiceType(serviceTypeID)
            });
            this.updateFields({ services }, false);
            API.saveApptService(this.state.appt.id, null, serviceTypeID, { isDeleted: false });
        }
    }

    async removeNonChimneyService(serviceTypeID) {
        const { appt } = this.state;
        const services = [...appt.services];
        const index = services.findIndex(ast => ast.serviceTypeID == serviceTypeID);
        if (index != -1) {
            services.splice(index, 1);
            this.updateFields({ services }, false);
            API.saveApptService(this.state.appt.id, null, serviceTypeID, { isDeleted: true });
        }
    }

    async removeService(chimneyID, serviceTypeID) {
        const { appt } = this.state;
        const services = [...appt.services];
        const index = services.findIndex(ast => ast.chimneyID == chimneyID && ast.serviceTypeID == serviceTypeID);
        if (index != -1) {
            services.splice(index, 1);
            this.updateFields({ services }, false);
            API.saveApptService(this.state.appt.id, chimneyID, serviceTypeID, { isDeleted: true });
        }
    }

    //---------------------------------------------------------------------------------------------------------------
    // Product
    //---------------------------------------------------------------------------------------------------------------

    async updateProductFields(id, fields, save) {
        const { appt } = this.state;
        const products = [...appt.products];
        let index = products.findIndex(ap => ap.id == id);
        if (index == -1) {
            index = products.length;
            products.push({ id, quantity: 1 });
        }

        const product = { ...products[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            product[fieldName] = value;

            // If changing product, get default unit price
            if (fieldName == 'productID') {
                product.product = API.productsByID[value];
                if (product.product) {
                    product.unitPrice = product.product.price;
                    product.totalPrice = product.unitPrice * (Number(product.quantity) || 0);
                    fields.unitPrice = product.unitPrice;
                    fields.totalPrice = product.totalPrice;
                }
            }

            // Update total price
            product.totalPrice = (Number(product.unitPrice) || 0) * (Number(product.quantity) || 0);
            fields.totalPrice = product.totalPrice;
        }
        products[index] = product;
        await this.updateFields({ products }, false);
        if (save) {
            API.saveProduct(this.state.appt.id, id, fields);
            this.updateFields({ productPrice: this.state.appt.productPrice }, true);
        }
    }

    updatePaymentFields(id, fields, save) {
        const { appt } = this.state;
        const payments = [...appt.payments];
        let index = payments.findIndex(ap => ap.id == id);
        if (index == -1) {
            index = payments.length;
            payments.push({ id });
        }
        const payment = { ...payments[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            payment[fieldName] = value;
        }
        payments[index] = payment;
        this.updateFields({ payments }, false);

        if (save) {
            API.savePayment(this.state.appt.id, id, fields);
        }
    }

    //---------------------------------------------------------------------------------------------------------------
    // Payment
    //---------------------------------------------------------------------------------------------------------------

    async removePayment(id) {
        const { appt } = this.state;
        const payments = [...appt.payments];
        const index = payments.findIndex(ap => ap.id == id);
        if (index != -1) {
            payments.splice(index, 1);
            this.updateFields({ payments }, false);
            API.savePayment(appt.id, id, { isDeleted: true });
        }
    }

    async removeProduct(id) {
        const { appt } = this.state;
        const products = [...appt.products];
        const index = products.findIndex(ap => ap.id == id);
        if (index != -1) {
            products.splice(index, 1);
            this.updateFields({ products }, false);
            API.saveProduct(this.state.appt.id, id, { isDeleted: true });
        }
    }

    //---------------------------------------------------------------------------------------------------------------
    // Render
    //---------------------------------------------------------------------------------------------------------------

    render() {
        const { isLoading, appt, customerSources, priceSchemes, products } = this.state;
        const { id, history } = this.props;
        const tab = this.props.tab || '';
        let prevTab = null;
        let nextTab = null;

        if (isLoading) {
            return (<Loader />);
        }

        let InnerComponent;
        switch (tab) {
            case '': 
                InnerComponent = ApptDetails;
                prevTab = null;
                nextTab = 'jobs';
                break;
            case 'jobs':
                InnerComponent = ApptJobs;
                prevTab = '';
                nextTab = 'products';
                break;
            case 'products':
                InnerComponent = ApptProducts;
                prevTab = 'jobs';
                nextTab = 'summary';
                break;
            case 'summary':
                InnerComponent = ApptSummary;
                prevTab = 'products';
                nextTab = 'signature';
                break;
            case 'signature':
                InnerComponent = ApptSignature;
                prevTab = 'summary';
                nextTab = null;
                break;
        }
        
        return (<>

            <NavPills className="section-nav">
                <NavPills.Pill 
                    icon="fa-solid fa-house-chimney"
                    title="Details"
                    isActive={tab == ''}
                    url={`/appt/${id}`}
                />
                <NavPills.Pill 
                    icon="fa-solid fa-list-check"
                    title="Jobs"
                    isActive={tab == 'jobs'}
                    url={`/appt/${id}/jobs`}
                />
                <NavPills.Pill 
                    icon="fa-solid fa-cart-shopping"
                    title="Products"
                    isActive={tab == 'products'}
                    url={`/appt/${id}/products`}
                />
                <NavPills.Pill
                    icon="fa-solid fa-check"
                    title="Summary"
                    isActive={tab == 'summary'}
                    url={`/appt/${id}/summary`}
                />
                <NavPills.Pill
                    icon="fa-solid fa-pencil-alt"
                    title="Signature"
                    isActive={tab == 'signature'}
                    url={`/appt/${id}/signature`}
                />
            </NavPills>
            
            <section className="appt-page">

                <ErrorBoundary>

                    <InnerComponent
                        history={history}
                        appt={appt}
                        customerSources={customerSources}
                        priceSchemes={priceSchemes}
                        products={products}

                        load={this.load}
                        setIsLoading={this.setIsLoading}
                        getFieldValue={this.getFieldValue}
                        updateFields={this.updateFields}
                        saveField={this.saveField}
                        updateServiceFields={this.updateServiceFields}
                        updateChimneyFields={this.updateChimneyFields}
                        addService={this.addService}
                        addNonChimneyService={this.addNonChimneyService}
                        removeService={this.removeService}
                        removeNonChimneyService={this.removeNonChimneyService}
                        updateProductFields={this.updateProductFields}
                        toggleSweepNest={this.toggleSweepNest}
                        removeProduct={this.removeProduct}
                        updatePaymentFields={this.updatePaymentFields}
                        removePayment={this.removePayment}
                    />

                </ErrorBoundary>
                
            </section>

            <section className="bottom-nav">

                {prevTab !== null &&
                    <Link className="btn btn-secondary left" to={`/appt/${id}/${prevTab}`}>
                        <span className="fa-solid fa-chevron-left" />{' '}
                        Back
                    </Link>
                }

                {nextTab !== null &&
                    <Link className="btn btn-primary right" to={`/appt/${id}/${nextTab}`}>
                        Next{' '}
                        <span className="fa-solid fa-chevron-right" />
                    </Link>
                }

                {tab == 'signature' &&
                    <button className="btn btn-primary right" onClick={() => this.tryCompleteAppt()}>
                        <span className="fa-solid fa-check" />{' '}
                        Complete Appointment
                    </button>
                }

            </section>

        </>);
    }

}

export default Appt;