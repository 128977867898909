import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

// Services & helpers
import TextHelpers from 'helpers/TextHelpers';

//-----------------------------------------------------------------

class NextApptModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                appt: opt.appt
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    render() {
        const {
            isOpen,
            appt
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="next-appt-modal" backdrop="static" keyboard={false}>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                        Close
                    </button>
                    <Link to={`/appt/${appt.id}`} type="button" className="btn btn-primary ms-auto">
                        Go to appointment
                    </Link>
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const { appt } = this.state;

        return (<>

            <h2>Your next appointment is...</h2>

            <div className="field">
                <div className="field-name">Time</div>
                <div className="field-value">
                    {appt.time}
                </div>
            </div>
            <div className="field">
                <div className="field-name">Customer</div>
                <div className="field-value">

                    {appt.customer.companyName &&
                        <div>
                            {appt.customer.companyName}
                        </div>
                    }
                    {(appt.customer.firstName || appt.customer.lastName) &&
                        <div>
                            {appt.customer.title} {appt.customer.firstName} {appt.customer.lastName}
                        </div>
                    }

                </div>
            </div>
            <div className="field">
                <div className="field-name">Address</div>
                <div className="field-value">
                    {TextHelpers.formatAddress(appt.property.address)}
                </div>
            </div>
            <div className="field">
                <div className="field-name">Directions</div>
                <div className="field-value">
                    {appt.property.directions}
                </div>
            </div>
            {!!appt.property.whatThreeWords &&
                <div className="field">
                    <div className="field-name">W3W</div>
                    <div className="field-value">
                        <a className="btn btn-primary" href={`https://what3words.com/${appt.property.whatThreeWords}`} target="_blank">
                            {appt.property.whatThreeWords}
                        </a>
                    </div>
                </div>
            }
            <div className="field">
                <div className="field-name">Job(s)</div>
                <div className="field-value">
                    {TextHelpers.getApptSummary(appt)}
                </div>
            </div>
        </>);
    }
}

export default NextApptModal;