// Libs
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logoImage from 'images/logo.png';

import App from 'App';

//-------------------------------------------------------------------------------------------------------------------

class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        window.updateSyncStatus = (syncStatus, syncError) => {
            window.syncStatus = syncStatus;
            this.setState({ syncStatus, syncError });
        }
    }

    onClickDebugButton() {
        const { syncStatus, syncError } = this.state;
        if (syncStatus == 'error' && syncError) {
            alert(syncError);
        }
    }

    render() {
        const { topNavContent } = this.props;
        const { syncStatus } = this.state;
        let syncIcon = null;
        switch (syncStatus) {
            case 'error':
                syncIcon = (<span className="fa-solid fa-triangle-exclamation" />);
                break;
            case 'busy':
                syncIcon = (<span className="fa-solid fa-spinner spinning-icon" />);
                break;
            default:
                syncIcon = (<span className="fa-solid fa-check" />);
                break;
        }

        return (
            <nav className="top-nav">
                
                <Link to="/day-sheet" className="logo-container">
                    <img src={logoImage} />
                </Link>

                {window.location.pathname != '/day-sheet' &&
                    <Link to="/day-sheet" className="btn btn-primary home-btn">
                        <span className="fa-solid fa-chevron-left" />
                    </Link>
                }

                {!!topNavContent &&
                    <div className="property-address">

                        {topNavContent}

                    </div>
                }

                <div className="debug-menu">
                    
                    <button className={`btn ${syncStatus == 'error' ? 'btn-danger' : ''}`} onClick={() => this.onClickDebugButton()}>
                        {syncIcon}{' '}
                        Version {App.VERSION}
                    </button>
                    
                </div>
            </nav>
        );
    }
}

export default NavMenu;