import React from 'react';
import { Link } from 'react-router-dom';

class NavPills extends React.Component {

    render() {
        const {
            className
        } = this.props;
        const children = React.Children.toArray(this.props.children);

        if (children.length > 0) {
            return (
                <ul className={`nav nav-pills ${className || ''}`}>
                    {children}
                </ul>
            );
        }
        
        return null;
    }

}

NavPills.Pill = class NavPillsPill extends React.Component {
    
    render() {
        const { icon, title, url, isActive } = this.props;
        
        return (
            <li className="nav-item">
                <Link className={`nav-link ${isActive ? 'active' : ''}`} to={url}>
                    {!!icon && <>
                        <span className={icon} />{' '}
                    </>}
                    {title}
                </Link>
            </li>
        );
    }

};

export default NavPills;