import moment from 'moment';

export default class ReferenceHelpers {

    static CustomerTypes = [
        // Individual - Single property
        { value: 'OwnerOccupier', text: 'Owner / Occupier' },
        { value: 'Tenant', text: 'Tenant' },
        
        // Business - Multiple properties
        { value: 'LettingAgent', text: 'Letting Agent' },
        { value: 'Estate', text: 'Estate' },
        { value: 'HousingAssociation', text: 'Housing Association' },
        { value: 'Landlord', text: 'Landlord' },
        { value: 'Company', text: 'Company' },
        { value: 'MultiplePropertyOwner', text: 'Owner of Multiple Properties' }
    ];
    
    static Months = [
        { value: 1,  text: 'Jan' },
        { value: 2,  text: 'Feb' },
        { value: 3,  text: 'Mar' },
        { value: 4,  text: 'Apr' },
        { value: 5,  text: 'May' },
        { value: 6,  text: 'Jun' },
        { value: 7,  text: 'Jul' },
        { value: 8,  text: 'Aug' },
        { value: 9,  text: 'Sep' },
        { value: 10, text: 'Oct' },
        { value: 11, text: 'Nov' },
        { value: 12, text: 'Dec' },
    ];

    static isIndividual(customerType) {
        return (
            !customerType ||
            customerType == 'OwnerOccupier' ||
            customerType == 'Tenant'
        );
    }
    
    static Floors = [
        { value: 'Ground', label: 'Ground' },
        { value: 'First', label: 'First' },
        { value: 'Second', label: 'Second' },
        { value: 'Basement', label: 'Basement' },
        { value: 'Cellar', label: 'Cellar' },
        { value: 'Lower Ground', label: 'Lower Ground' },
        //{ value: 'Unspecified', label: 'Unspecified' }
    ];
    
    static Locations = [
        { value: 'Living Room', label: 'Living Room' },
        { value: 'Kitchen', label: 'Kitchen' },
        { value: 'Bedroom', label: 'Bedroom' },
        { value: 'Dining Room', label: 'Dining Room' },
        { value: 'Snug', label: 'Snug' },
        { value: 'Study', label: 'Study' },
        { value: 'Office', label: 'Office' },
        { value: 'Drawing Room', label: 'Drawing Room' },
        { value: 'Sitting Room', label: 'Sitting Room' },
        { value: 'Front Room', label: 'Front Room' },
        { value: 'Master Bedroom', label: 'Master Bedroom' },
        { value: 'Bar', label: 'Bar' },
        { value: 'Conservatory', label: 'Conservatory' },
        { value: 'Lobby', label: 'Lobby' },
        //{ value: 'Unspecified', label: 'Unspecified' }
    ];

}