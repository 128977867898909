// Libs
import React from 'react';

//-------------------------------------------------------------------------------------------------------------------

class ButtonOptions extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { className } = this.props;
        const options = this.props.options.filter(o => !!o);
        if (options.length == 0) return null;
        return (
            <div className={`button-options ${className || ''}`}>
                {options.map(o => this.renderOption(o))}
            </div>
        );
    }

    renderOption(option) {
        const { btnClassName, value, onSelect } = this.props;
        const isActive = (
            value === option.value ||
            (Array.isArray(value) && value.indexOf(option.value) != -1) ||
            (typeof(value) == 'object' && !Array.isArray(value) && value && value[option.value])
        );

        return (
            <button key={option.value} type="button" className={`btn ${btnClassName || ''} ${isActive ? 'active' : ''}`} onClick={() => onSelect(option.value, option.text)}>
                {isActive ?
                    <span className="fa-regular fa-square-check" /> :
                    <span className="fa-regular fa-square" />
                }{' '}
                {option.text}
            </button>
        );
    }
}

export default ButtonOptions;